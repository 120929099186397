<template>
  <div class="scope-component" :class="'component-scope-' + scopes.scope">
    <div v-if="scopes.scope === '1' || scopes.scope === '3' || scopes.scope === '4'" class="scope-header">
      {{ header }}
    </div>
    <div class="scope-wrapper" :class="'scope-' + scopes.scope">
      <div v-if="scopes.scope_icon !== ''" class="scope-icon">
        <img :src="getSettingIcon(scopes.scope_icon)" alt="" />
      </div>
      <div class="scope-category-wrapper">
        <div class="scope-category"  :class="(!isEnableScope3 && index === 0)?'disabled':''"
             v-for="(category, index) in scopes.categories" :key="index">
          <div @click="(isEnableScope3 && index === 0) || index !== 0?redirectToRegisData(scopes, category):false" class="category-name">
            <div class="d-flex align-center" style="max-width: 100%;">
              <div v-if="scopes.scope === '3'" class="category-index">
                <img
                  v-if="scopes.align === 'left'"
                  :src="getSettingIcon('scope' + scopes.scope + '/index/index' + parseInt(index + 1) + '.svg')"
                  alt=""
                />
                <img
                  v-else
                  :src="getSettingIcon('scope' + scopes.scope + '/index/index' + parseInt(index + 9) + '.svg')"
                  alt=""
                />
              </div>
              <img
                v-if="scopes.align === 'right'"
                :src="getSettingIcon('scope' + scopes.scope + '/c' + parseInt(index + 9) + '.svg')"
                alt=""
              />
              <img v-else :src="getSettingIcon('scope' + scopes.scope + '/c' + parseInt(index + 1) + '.svg')" alt="" />
              <div class="name">{{ category.name }}</div>
            </div>
            <div class="category-percent">
            <svg class="stat-circle" width="20" height="20">
              <circle class="bg" cx="10" cy="10" r="9"></circle>
              <circle class="progress" cx="10" cy="10" r="9" :style="'--percent :' + (category.total_month_is_register_data * 100 / 12 )" ></circle>
            </svg>
            <div class="category-percent__text" >{{category.total_month_is_register_data}}</div>
          </div>
          </div>
          <div v-if="(isEnableScope3 && index === 0) || index !== 0"
            class="helper-icon"
            :class="{ right: scopes.align === 'right' }"
            @click="addTooltip($event)"
            @mouseover="addTooltip($event)"
          >
            <img
              :src="getSettingIcon('helper.svg')"
              @mouseover="changeTooltipImage($event, index)"
              @mouseleave="changeTooltipImage($event, index, 'mouseleave')"
              alt=""
            />
            <div v-show="hoverIndex === index" class="helper-icon tooltip" v-html="category.tooltip"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { getDefaultMethods } from "@/utils/updateDataBeforeRedirect";
export default {
  name: 'ScopeComponent',
  props: {
    isEnableScope3: {
      type   : Boolean,
      default: true
    },
    header: {
      type: String,
      default: 'This is default header',
    },

    scopes: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      focusElement: null,
      hoverIndex: null,
    };
  },
  mounted() {
    document.addEventListener('scroll', this.removeTooltip);
  },
  computed: {
    ...mapState('registerData', ['startMonth']),
    ...mapState('userData', ['isSettingPcaf']),
  },
  methods: {
    ...mapActions('registerData', ['updateDataMonth', 'updateStartMonth']),
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/images/emissions/${image}`);
      }
      return '';
    },
    async redirectToRegisData(scopes, category) {
      this.updateDataMonth(this.startMonth);
      const cateId = category.id;
      const scope = cateId === 16 ? 3 : scopes.scope;
      const url = `/emissions/view/${scope}/${cateId}`;
      await getDefaultMethods(parseInt(scope), parseInt( cateId), this.isSettingPcaf);
      this.$store.dispatch('newRegisterData/updateIsApproved', true);
      this.$router.push({ path: url });
      //location.href = url;
    },
    removeTooltip() {
      if (this.focusElement) {
        this.focusElement.classList.remove('tooltip-icon');
      }
    },
    addTooltip(event) {
      this.focusElement = event.target.parentElement;
      this.focusElement.classList.add('tooltip-icon');
    },
    changeTooltipImage(event, index, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper.svg');
        this.hoverIndex = null;
      } else {
        event.target.src = this.getSettingIcon('helper-active.svg');
        this.hoverIndex = index;
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.removeTooltip);
  },
};
</script>

<style lang="scss" scoped>
.scope-component {
  background: $bgLight;
  border-radius: 8px;
  padding: 8px;
  .scope-header {
    color: $monoDark;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;
    padding: 16px 0;
    margin: 0 auto;
  }
  .scope-wrapper {
    &.scope-1 {
      background-color: $blueDark;
      .scope-category-wrapper {
        background: linear-gradient(180deg, #0072a3 0%, #c8deea 100%);
      }
    }
    &.scope-2 {
      background-color: $redMid;
      .scope-category-wrapper {
        background: linear-gradient(180deg, $redMid 0%, #eacac8 100%);
      }
    }
    &.scope-3 {
      background-color: $greenMid;
      .scope-category-wrapper {
        background: linear-gradient(180deg, $greenDark 0%, #c7e6bc 100%);
      }
    }
    border-radius: 4px;

    .scope-icon {
      height: 65px;
      display: flex;
      img {
        width: 80px;
        height: 25px;
        margin: 24px auto 16px;
      }
    }
    .scope-category-wrapper {
      display: flex;
      flex-flow: column;
      border-radius: 0px 0px 4px 4px;
      .scope-category {
        display: flex;
        align-items: stretch;
        background-color: $monoOffWhite;
        border-radius: 4px;
        margin: 0 8px 8px;
        cursor: pointer;
        .category-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          max-width: calc(100% - 40px);
          padding: 8px 11px;
          z-index: 1;
          img {
            width: 30px;
            height: 30px;
          }
          
          .category-index {
            img {
              width: 20px;
              height: 20px;
            }
          }
          .name {
            color: $monoBlack;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            margin: 0 8px;
          }
          .category-percent {
            box-sizing: border-box;
            position: relative;
            height: 20px;
          }
          .category-percent__text {
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            // font-family: 'Source Han Sans JP';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            color: $goldMid;
          }
          .stat-circle {
            transform: rotate(270deg);
            circle.bg {
              fill: none;
              stroke: #f1f1f1;
              stroke-width: 1;
            }
            circle.progress
            {
              fill: none;
              stroke: $goldMid;
              stroke-width: 1;
              stroke-dasharray: 57px;
              stroke-dashoffset: calc(57px - (57px * var(--percent))/100);
            }
            text
            {
              font-size: 10px;
              text-anchor: middle;
              fill: $goldMid;
            }
          }
          .category-status {
            width: 20px;
            height: 36px;
            display: flex;
            align-items: center;
            img {
              width: 10px;
              height: 10px;
            }
          }
        }
        .helper-icon {
          width: 40px;
          min-height: 100%;
          display: inline-block;
          position: relative;
          background-color: #eef0f0;
          display: flex;
          align-items: center;
          img {
            z-index: 1;
          }
          &.tooltip {
            display: block;
            justify-content: left;
            background: $monoWhite;
            border-radius: 4px;
            color: $monoBlack;
            margin: 0 auto;
            font-size: 15px;
            padding: 10px;
            height: unset;
            width: 315px;
            max-width: max-content;
            position: absolute;
            right: 0px;
            left: unset;
            bottom: 50px;
            z-index: 9999;
            box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044),
              0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351),
              0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          }
        }
        .category-index {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 8px;
        }
        &:hover {
          background-color: $monoWhite;
        }
      }
      > .disabled {
        opacity: 0.4;
        cursor: unset;
        .category-name {
          .name {
            color: #7c898c;
          }
        }

        &:hover {
          background: #f7f7f2;
        }
      }
    }
  }
}
.component-scope-1 {
  padding-bottom: unset;
  border-radius: 4px 4px 0px 0px;
}
.component-scope-2 {
  border-radius: 0px 0px 4px 4px;
}
.component-scope-4 {
  margin-top: 48px;
}

@include large-desktop {
  .tooltip-icon {
    &.right {
      &:hover:before {
        right: 15px !important;
        left: unset !important;
      }
    }
    &:hover:before {
      left: 15px !important;
      right: unset !important;
    }
  }
}

@include desktop {
  .component-scope-4 {
    display: flex;
    align-items: center;
    width: 100%;
    .scope-header {
      width: calc(100% / 3);
      padding: 14px 0;
    }
    .scope-wrapper {
      width: calc(100% / 3 * 2);
      .scope-category-wrapper {
        display: flex;
        flex-flow: row !important;
        .scope-category {
          width: 50%;
          margin: unset;
          &:first-child {
            margin-right: 8px;
          }
          > .tooltip-icon {
            &:last-child {
              &:hover:before {
                right: 15px !important;
                left: unset !important;
              }
            }
            &:hover:before {
              left: 15px !important;
              right: unset !important;
            }
          }
        }
      }
    }
  }
}

@include tablet {
  .scope-category-wrapper {
    .category-name {
      .name {
        max-width: calc(100% - (30px + 14px));
        word-break: break-word;
      }
    }
  }
}
</style>
